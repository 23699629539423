/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "styles/main.sass";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

// const { config } = require('@fortawesome/fontawesome-svg-core')

export const onClientEntry = () => {
  /* Prevents fontawesome auto css insertion */
  config.autoAddCss = false;
};
