// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bedienung-index-js": () => import("./../../../src/pages/bedienung/index.js" /* webpackChunkName: "component---src-pages-bedienung-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-regeln-index-js": () => import("./../../../src/pages/regeln/index.js" /* webpackChunkName: "component---src-pages-regeln-index-js" */),
  "component---src-pages-spielen-index-js": () => import("./../../../src/pages/spielen/index.js" /* webpackChunkName: "component---src-pages-spielen-index-js" */)
}

